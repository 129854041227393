import { createSlice } from "@reduxjs/toolkit";

// import NursePic from "../../assets/images/avatars/nurse.jpg";
// import MarkPic from "../../assets/images/avatars/MarkAppleby.jpg";
// import DCashPic from "../../assets/images/avatars/mr-david-cash.jpg";
// import EricPic from "../../../assets/images/avatars/eric.jpg";
import IvanovaPic from "../../assets/images/avatars/dobrinka-ivanova.jpeg";
import NickPic from "../../assets/images/avatars/NickTaylor.jpg";
import GuestPic from "../../assets/images/avatars/guest-user.jpg";

export interface CommentTimelineState {
  comments: any[];
}

const initialState: CommentTimelineState = {
  comments: [
    {
      id: 1,
      content:
        "Thanks for clarifying. We’ll need to highlight the risks associated with general anaesthesia in our argument. Let’s also confirm if the registrar attempted to follow up with the MDT before making this decision",
      by: "David Clark",
      speciality: "Lawyer",
      avatar: NickPic,
      date: "11/18/2024",
      replies: [],
    },

    {
      id: 2,
      content:
        "Given Elijah’s cardiovascular profile, regional anaesthesia would have been a safer choice. General anaesthesia can elevate stress on the heart, especially in patients with limited pre-surgical conditioning. This is a significant oversight.",
      by: "Dr Mark Evans",
      speciality: "Anaesthesiology",
      avatar: GuestPic,
      date: "11/18/2024",
      replies: [],
    },
    {
      id: 3,
      content:
        "I looked into the surgical notes, and it seems the anaesthesia registrar documented a server outage that prevented access to the patient’s full electronic medical record. But there’s no clear justification for disregarding the MDT’s recommendation.”",
      by: "Dobrinka Ivanova",
      speciality: "Paralegal",
      avatar: IvanovaPic,
      date: "11/15/2024",
      replies: [],
    },
    {
      id: 4,
      content:
        "Can someone confirm why general anaesthesia was used despite the MDT recommending regional anaesthesia for this patient? This deviation seems central to our case.",
      by: "David Clark",
      speciality: "Lawyer",
      avatar: NickPic,
      date: "11/15/2024",
      replies: [],
    },
  ],
};

// const initialState: CommentTimelineState = {
//   comments: [
//     {
//       id: 1,
//       content:
//         "Saw patient in the clinic and believe his quality of life and independence are hampered by his right hip osteoarthritis. Given his cardiac history, obesity and ongoing smoking, I referred him to both Mr. Cash (ortho) and Dr. Appleby (cardiology) for further evaluation. If he can be cleared from a CV perspective, I am in favor of him receiving a THR with supportive care, to possibly include a short stay at a rehab facility postop.",
//       by: "Dr Nick Taylor",
//       speciality: "G.P",
//       avatar: NickPic,
//       date: "01/27/2024",
//       replies: [],
//     },

//     {
//       id: 2,
//       content:
//         "Reviewed EPR and patient appears to be a good candidate for preop physiotherapy. Would recommend at least 2x/week for 4 weeks to enhance stamina and build strength in supportive musculature. Given he lives alone, recommend a short stay in a rehabilitation facility for daily physio following his procedure.",
//       by: "Dobrinka Ivanova",
//       speciality: "Physiotherapy",
//       avatar: IvanovaPic,
//       date: "01/28/2024",
//       replies: [],
//     },
//     {
//       id: 3,
//       content:
//         "Hip series show clear degenerative changes in the right hip, but bone density suggests he would be a good candidate for hip arthroplasty. ",
//       by: "Dr John Ferrier",
//       speciality: "Radiology",
//       avatar: GuestPic,
//       date: "01/29/2024",
//       replies: [],
//     },
//     {
//       id: 4,
//       content:
//         "Mr. Abani's right hip is severely impacted by arthritis, and would clearly benefit from a R-THR. Although heavyset, he's generally been active and independent all of his life, and I believe with proper prehabilitation and post-op physiotherapy, he might become even more physically active and lose some weight while preserving his independence. ",
//       by: "Mr David Cash",
//       speciality: "Orthopaedics",
//       avatar: DCashPic,
//       date: "02/01/2024",
//       replies: [
//         {
//           id: 1,
//           content:
//             "Evaluated patient in clinic and reviewed hospitalisation 5 years ago when he sustained a minor NSTEMI, resulting in a LAD coronary stent with preserved LVEF. Negative cardiac review of systems, but obesity and smoking risk factors. EKG in my office shows no ischemic or interval changes. Since his hip precludes a normal treadmill stress test, a pharmacologic nuclear stress test was ordered, and shows no acute focal abnormality. I would recommend 4-6 weeks prehab, smoking cessation and venous thromboembolism prophylaxis and regional anaesthesia to reduce the risk of an adverse perioperative cardiac event.",
//           by: "Dr Mark Appleby",
//           speciality: "Cardiology",
//           avatar: MarkPic,
//           date: "01/29/2024",
//         },
//       ],
//     },
//     {
//       id: 5,
//       content:
//         "We'd be delighted to care for Mr. Abani following his hip replacement surgery. We have physiotherapists on staff who could help restore his hip mobility as well as his independence, allowing him to transition home, likely in 5-7 days based on similar cases we've seen in the past.",
//       by: "Ms Janice Smith",
//       speciality: "Nursing Home",
//       avatar: NursePic,
//       date: "01/28/2024",
//       replies: [],
//     },
//   ],
// };

const commentTimelineSlice = createSlice({
  name: "commentTimeline",
  initialState,
  reducers: {
    changeCommentTimeline: (state, action) => {
      state.comments = action.payload;
    },
  },
});

export const { changeCommentTimeline } = commentTimelineSlice.actions;
export default commentTimelineSlice.reducer;
