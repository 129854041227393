// React imports
import React from "react";

// MUI imports
import { Box, Card, Chip, Grid, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";

// Component imports
import VerticalTimelinePlain from "./VerticalTimelinePlain";
import ThinlayerBox from "../aiBox/ThinlayerBox";
import VitruvianSekaniAbara from "../vitruvian/VitruvianSekaniAbara";

// Types
import { Timeline } from "../../types/extraction";

// Styled component for the timeline container
const TimelineContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "6px",
  padding: "20px",
  height: "90%",
  overflowX: "auto",
}));

// Interface for the component props
interface TimelineProps {
  timeline: Timeline;
  handleOpen: () => void;
}

const ComboTimeline = ({ timeline, handleOpen }: TimelineProps) => {
  return (
    <TimelineContainer>
      <Grid container spacing={8} columns={24}>
        <Grid
          item
          className="column-2"
          xs={24}
          sm={24}
          md={16}
          lg={16}
          xl={17}
          xxl={18}
        >
          <VerticalTimelinePlain handleOpen={handleOpen} timeline={timeline} />
        </Grid>
        <Grid
          item
          className="column-1"
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={7}
          xxl={6}
        >
          <Card sx={{ py: 5, px: 5 }}>
            <Stack
              direction="row"
              sx={{ flexWrap: "wrap" }}
              useFlexGap
              spacing={6}
            >
              <Box sx={{ flexGrow: 1, mb: 6 }}>
                <Typography variant="h4" color="InfoText" sx={{ mb: 3, ml: 2 }}>
                  Conditions
                </Typography>
                <Stack spacing={2}>
                  <Chip color="primary" label="Obesity" />
                  <Chip color="primary" label="Osteoarthritis" />
                  <Chip color="primary" label="Myocardial Infarction" />
                  <Chip color="primary" label="Stent-LAD Coranary Artery" />
                </Stack>
              </Box>
              <Box sx={{ width: "30%" }}>
                <VitruvianSekaniAbara />
              </Box>
            </Stack>
            <Typography variant="h4" color="InfoText" sx={{ mb: 3, ml: 2 }}>
              Summary
            </Typography>
            <ThinlayerBox>
              <Typography variant="body1" sx={{ pt: 2, px: 1, pb: 2 }}>
                Mr. Bailey is a 72-year-old, moderately obese (BMI = 36) male of
                South African descent being considered for a Right Total Hip
                Replacement secondary to osteoarthritis. Patient’s noteworthy
                past medical history includes: obesity, 50 pack-years of ongoing
                tobacco use, and a non-ST-elevation myocardial infarction.
              </Typography>
              <Typography variant="body1" sx={{ pt: 2, px: 1, pb: 2 }}>
                (NSTEMI) 5 years prior, resulting in a successful stenting of
                his left anterior descending (LAD) coronary artery. He has no
                cardiac symptoms, no shortness of breath with ambulation or
                signs of congestive heart failure (no peripheral oedema,
                pulmonary rales or orthopnea).
              </Typography>
            </ThinlayerBox>
            <Stack
              direction="row"
              sx={{ flexWrap: "wrap", mb: 6 }}
              useFlexGap
              spacing={6}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h4"
                  color="InfoText"
                  sx={{ mt: 6, mb: 3, ml: 2 }}
                >
                  Medications
                </Typography>
                <Stack spacing={2}>
                  <Chip
                    variant="outlined"
                    color="primary"
                    label="Aspirin 75mg"
                  />
                  <Chip
                    variant="outlined"
                    color="primary"
                    label="Atorvastatin 40mg"
                  />
                  <Chip
                    variant="outlined"
                    color="primary"
                    label="Metoprolol 50mg"
                  />
                  <Chip
                    variant="outlined"
                    color="primary"
                    label="Ramipril 5mg"
                  />
                </Stack>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h4"
                  color="InfoText"
                  sx={{ mt: 6, mb: 3, ml: 2 }}
                >
                  Allergies
                </Typography>
                <Stack spacing={2}>
                  <Chip color="info" label="Penicillin" />
                </Stack>
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </TimelineContainer>
  );
};

export default ComboTimeline;
