// react imports
import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

// MUI imports
import {
  Box,
  Breadcrumbs,
  Card,
  CardHeader,
  Link,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import {
  DataGridPro,
  GridEventListener,
  GridToolbar,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";

// Project imports
import TLPagebar from "../../components/navbar/appBar/TLPageBar";
import NewCase from "../../components/dialogs/NewCase";

//data imports
import caseListData from "../../assets/fakeData/caseListData";

// Styled component for page content
const PageContent = styled(Box)`
  padding: 6px 24px 24px 24px;
`;

// const RightTools = () => {
//   return (
//     <Box>
//       <NewCase />
//     </Box>
//   );
// };

function ChronDashboard() {
  const navigate = useNavigate();
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    navigate(`/case/${params.id}`);
    console.log(params.id);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Chronology Dashboard</title>
      </Helmet>
      <TLPagebar
        title="Dashboard"
        rightTools={<NewCase />}
        breakpointChildren="lg"
      />
      <PageContent>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 4, px: 1 }}>
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Typography color="text.primary">Dashboard</Typography>
        </Breadcrumbs>
        <Card>
          <CardHeader
            avatar={<MedicalServicesIcon color="primary" />}
            title="Case List"
          />
          <DataGridPro
            onRowClick={handleRowClick}
            disableColumnSelector // Disable the column selector
            disableDensitySelector // Disable the density selector
            checkboxSelection
            disableRowSelectionOnClick // Disable row selection on click
            slots={{ toolbar: GridToolbar }} // Set the toolbar slot to GridToolba
            slotProps={{
              toolbar: {
                showQuickFilter: true, // Show the quick filter in the toolbar
              },
            }}
            sx={{
              pt: 1,
              // [`& .${gridClasses.cell}`]: {
              //   pt: 4,
              //   pb: 1,
              // },
            }}
            initialState={{
              pinnedColumns: {
                left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
              }, // Set the pinned columns
              sorting: { sortModel: [{ field: "next_deadline", sort: "asc" }] }, // Set the sorting column
              density: "comfortable", // Set the density to comfortable
            }}
            rows={caseListData} // Set the rows of the DataGridPro to the timeline data
            columns={[
              // Define the columns for the DataGridPro
              {
                field: "case_id",
                headerName: "Case ID",
                width: 120,
              },
              {
                field: "client_name",
                headerName: "Claimant",
                width: 150,
              },
              {
                field: "case_name",
                headerName: "Case",
                flex: 1,
                minWidth: 150,
              },
              {
                field: "lead_solicitor",
                headerName: "Lawyer",
                width: 150,
              },
              {
                field: "case_type",
                headerName: "Category",
                width: 150,
              },
              {
                field: "case_stage",
                headerName: "Phase",
                width: 150,
              },
              {
                field: "key_documents",
                headerName: "Last Updated",
                flex: 1,
                minWidth: 150,
              },
              {
                field: "page_count",
                headerName: "Page Count",
                width: 120,
              },
            ]}
          />
        </Card>
      </PageContent>
    </React.Fragment>
  );
}

export default ChronDashboard;
