// React Imports
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utils Imports

// MUI Imports
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { DateTimeRangePicker } from "@mui/x-date-pickers-pro/DateTimeRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DateRange } from "@mui/x-date-pickers-pro/models";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

// Data imports
import exampleDoctors from "./exampleDoctors";
import exampleTags from "../../assets/fakeData/exampleTags";
import { Timeline } from "../../types/extraction";
import { changeCommentTimeline } from "../../redux/slices/mockTimelineSlice";

// Custom imports
import Tag from "./Tag";
import TypeIcon from "./TypeIcon";
// import SamplePdf from "../pdf/Sample";
import Thumbnail from "../pdf/Thumbnail";
import Spacer from "../navbar/appBar/Spacer";
import Comments from "../ramsay/mdt/patientView/Comments";

// Interface for the component props
interface NewEventFormProps {
  timeline: Timeline; // Timeline data
  open: boolean; // Boolean to control the dialog open state
  handleClose: () => void; // Function to handle closing the dialog
}

// Functional component for the new event form
const EventForm = ({ timeline, open, handleClose }: NewEventFormProps) => {
  let content = null;
  // Redux dispatch hook
  const dispatch = useDispatch();
  const selectedEvent = useSelector((state: any) => state.event.value);
  const event = timeline.find((item) => item.id === selectedEvent);
  // State to manage the date range values
  const [dateValues, setDateValues] = useState<DateRange<Date>>([null, null]);
  // State to manage the title input
  const [title, setTitle] = useState("");
  // State to manage the description input
  const [description, setDescription] = useState("");
  // State to manage the author input
  const [author, setAuthor] = useState("");
  // State to manage the role input
  const [role, setRole] = useState("");
  // State to manage the type input
  const [type, setType] = useState("");
  //State for edit or read event data
  const [eventView, setEventView] = useState("read");
  const [tags, setTags] = useState([
    { id: 0, name: "", bgcolor: "", color: "" },
  ]);
  const [showTags, setShowTags] = useState(false);

  const handleEdit = () => {
    // console.log("Edit event: ", event);
    setEventView("edit");
    if (event && event.event_type) {
      setType(event.event_type);
    }

    if (event && event.event_title) {
      setTitle(event.event_title);
    }
    if (event && event.event_abstract) {
      setDescription(event.event_abstract);
    }
    if (event && event.author) {
      setAuthor(event.author);
    }
    if (event && event.author_role) {
      setRole(event.author_role);
    }
    if (event && event.event_date && event.event_end) {
      setDateValues([new Date(event.event_date), new Date(event.event_end)]);
    }
    if (event && event.tags) {
      setTags(event.tags);
    }
  };

  // handler for saving the edited event
  const handleSave = () => {
    dispatch(
      changeCommentTimeline(
        timeline.map((item) =>
          item.id === selectedEvent
            ? {
                ...item,
                event_title: title,
                event_abstract: description,
                event_date: dateValues[0]?.toString(),
                event_end: dateValues[1]?.toString(),
                tags: tags,
                author: author,
                author_role: role,
                event_type: type,
              }
            : item
        )
      )
    );
    setEventView("read");
    handleClose(); // Close the dialog
    // Reset the form fields
    setTitle("");
    setDescription("");
    setDateValues([null, null]);
    setAuthor("");
    setRole("");
    setType("");
    setTags([{ id: 0, name: "", bgcolor: "", color: "" }]);
  };

  // handler for removing a tag
  const handleRemoveTag = (tagIndex: number) => {
    // console.log("Remove tag: ", tagIndex);
    const updatedTags = tags.filter((tag, index) => index !== tagIndex);
    setTags(updatedTags);
  };

  // Handler for adding a tag
  const handleAddTag = (tag: any) => {
    // console.log("Add tag: ", tag);
    setTags([...tags, tag]);
  };

  // Handler for form submission
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault(); // Prevent default form submission

    const id = timeline.length + 1; // Generate a unique ID for the new event
    dispatch(
      changeCommentTimeline([
        ...timeline,
        {
          id: id,
          event_title: title,
          event_abstract: description,
          event_date: dateValues[0]?.toString(),
          event_end: dateValues[1]?.toString(),
          author: author,
          author_role: role,
          event_type: type,
        },
      ])
    ); // Dispatch the action to update the timeline

    handleClose(); // Close the dialog
    // Reset the form fields
    setTitle("");
    setDescription("");
    setDateValues([null, null]);
    setAuthor("");
    setRole("");
    setType("");
  };

  let contentToolbar = (
    <Toolbar>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1, px: 6 }}>
        Event Details
      </Typography>
      {eventView === "read" ? (
        <Button color="inherit" onClick={handleEdit}>
          Edit
        </Button>
      ) : (
        <Button color="secondary" variant="contained" onClick={handleSave}>
          Save
        </Button>
      )}
    </Toolbar>
  );

  if (selectedEvent === -1) {
    contentToolbar = (
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, px: 6 }}>
          Add New Event
        </Typography>
      </Toolbar>
    );
    content = (
      <Box
        sx={{
          width: "500px",
          padding: "10px",
        }}
      >
        {/* Form element with submit handler */}
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Stack useFlexGap spacing={4}>
              {/* Date range picker for event start and end */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimeRangePicker
                  localeText={{ start: "Event start", end: "Event end" }}
                  value={dateValues}
                  onChange={(newValue) => {
                    setDateValues(newValue);
                  }}
                />
              </LocalizationProvider>
              {/* Text field for title input */}
              <TextField
                id="title"
                label="Title"
                variant="filled"
                fullWidth
                value={title}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTitle(event.target.value);
                }}
              />
              {/* Text field for description input */}
              <TextField
                id="outlined-textarea"
                label="Description"
                placeholder="Description"
                multiline
                fullWidth
                variant="filled"
                value={description}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setDescription(event.target.value);
                }}
              />
              {/* Autocomplete for author input */}
              <Autocomplete
                id="author"
                freeSolo
                value={author}
                onChange={(event: any, newValue: string | null) => {
                  if (newValue) setAuthor(newValue);
                }}
                options={exampleDoctors.map((option) => option.name)}
                renderInput={(params) => (
                  <TextField {...params} label="Author" variant="filled" />
                )}
              />

              {/* Text field for role input */}
              <TextField
                id="role"
                label="Role"
                variant="filled"
                fullWidth
                value={role}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setRole(event.target.value);
                }}
              />
              {/* Select field for type input */}
              <FormControl fullWidth>
                <InputLabel id="type">Type</InputLabel>

                <Select
                  id="type"
                  label="Type"
                  value={type}
                  onChange={(event: SelectChangeEvent) => {
                    setType(event.target.value as string);
                  }}
                  variant="filled"
                >
                  <MenuItem value="Note">Note</MenuItem>
                  <MenuItem value="Meeting">Meeting</MenuItem>
                  <MenuItem value="Consultation">Consultation</MenuItem>
                  <MenuItem value="Observation">Observation</MenuItem>
                  <MenuItem value="Appointment">Appointment</MenuItem>
                  <MenuItem value="Procedure">Procedure</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            {/* Button to cancel and close the dialog */}
            <Button onClick={handleClose}>CANCEL</Button>
            {/* Button to submit the form */}
            <Button variant="contained" type="submit" color="primary">
              ADD
            </Button>
          </DialogActions>
        </form>
      </Box>
    );
  } else {
    content = (
      <Box
        sx={{
          maxWidth: "1000px",
          minWidth: "600px",
          padding: "10px",
          display: "flex",
        }}
      >
        <Box sx={{ px: 6, pt: 6, width: "600px" }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Box>
              {/* ************* Event Type ************ */}
              {eventView === "read" && event?.event_type ? (
                <TypeIcon type={event.event_type} />
              ) : (
                <FormControl sx={{ width: "150px", mt: 4 }}>
                  <InputLabel id="type">Type</InputLabel>

                  <Select
                    id="type"
                    label="Type"
                    value={type}
                    onChange={(event: SelectChangeEvent) => {
                      setType(event.target.value as string);
                    }}
                    sx={{ mt: -4 }}
                    variant="filled"
                  >
                    <MenuItem value="Note">Note</MenuItem>
                    <MenuItem value="Meeting">Meeting</MenuItem>
                    <MenuItem value="Consultation">Consultation</MenuItem>
                    <MenuItem value="Observation">Observation</MenuItem>
                    <MenuItem value="Appointment">Appointment</MenuItem>
                    <MenuItem value="Procedure">Procedure</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Box>
            {/* ************* Event Date ************ */}
            {eventView === "read" && event?.event_date ? (
              <Box sx={{ color: "#9b9b9b" }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {event?.event_date &&
                    new Date(event.event_date).toLocaleString()}
                </Typography>
              </Box>
            ) : (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimeRangePicker
                  localeText={{ start: "Event start", end: "Event end" }}
                  value={dateValues}
                  onChange={(newValue) => {
                    setDateValues(newValue);
                  }}
                />
              </LocalizationProvider>
            )}
          </Stack>

          {/* ************* Event Title ************ */}
          {eventView === "read" && event?.event_title ? (
            <Typography sx={{ pt: 4 }} variant="h1">
              {event?.event_title}
            </Typography>
          ) : (
            <TextField
              id="title"
              label="Title"
              variant="filled"
              fullWidth
              value={title}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(event.target.value);
              }}
              sx={{ mt: 2 }}
            />
          )}

          <Stack
            direction="row"
            spacing={2}
            sx={{ mt: 2 }}
            divider={<Divider orientation="vertical" flexItem />}
          >
            {/* ************* Event Author ************ */}
            {eventView === "read" && event?.author ? (
              <Typography variant="h5">
                <strong>{event?.author && event.author}</strong>
              </Typography>
            ) : (
              <Autocomplete
                id="author"
                freeSolo
                value={author}
                sx={{ width: "500px" }}
                onChange={(event: any, newValue: string | null) => {
                  if (newValue) setAuthor(newValue);
                }}
                options={exampleDoctors.map((option) => option.name)}
                renderInput={(params) => (
                  <TextField {...params} label="Author" variant="filled" />
                )}
              />
            )}

            {/* ************* Event Author Role ************ */}
            {eventView === "read" && event?.author_role ? (
              <Typography variant="h5" color="GrayText">
                {event?.author_role && event.author_role}
              </Typography>
            ) : (
              <TextField
                id="role"
                label="Role"
                variant="filled"
                fullWidth
                value={role}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setRole(event.target.value);
                }}
              />
            )}
          </Stack>
          {/* ************* Event Tags ************ */}
          {eventView === "read" && event?.tags ? ( //read mode
            <Stack direction="row" sx={{ flexWrap: "wrap", pt: 3, gap: 1 }}>
              {event?.tags &&
                event.tags.map((tag: any, index: number) => (
                  <Tag
                    key={index}
                    id={tag.id}
                    name={tag.name}
                    color={tag.color}
                    bgcolor={tag.bgcolor}
                  />
                ))}
            </Stack>
          ) : (
            //edit mode
            <Box>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  flexWrap: "wrap",
                  alignItems: "center",
                  mt: 3,
                  p: 2,
                  border: "1px solid #E0E0E0",
                  borderRadius: "5px",
                }}
              >
                {tags &&
                  tags.map((tag: any, index: number) => (
                    <Box key={index} onClick={() => handleRemoveTag(index)}>
                      <Tag
                        key={index}
                        id={tag.id}
                        name={tag.name}
                        color={tag.color}
                        bgcolor={tag.bgcolor}
                        icon={<CancelIcon />}
                      />
                    </Box>
                  ))}
                <Button
                  onClick={() => setShowTags(true)}
                  size="small"
                  endIcon={<AddCircleIcon />}
                >
                  Add Tag
                </Button>
              </Stack>
              <Collapse in={showTags}>
                <Stack
                  direction="row"
                  sx={{
                    flexWrap: "wrap",
                    gap: 2,
                    alignItems: "center",
                    mt: 3,
                    p: 2,
                    border: "1px solid #E0E0E0",
                    borderRadius: "5px",
                  }}
                >
                  {exampleTags.map((tag: any, index: number) => (
                    <Box key={index} onClick={() => handleAddTag(tag)}>
                      <Tag
                        key={index}
                        id={tag.id}
                        name={tag.name}
                        color={tag.color}
                        bgcolor={tag.bgcolor}
                        icon={<AddCircleIcon />}
                      />
                    </Box>
                  ))}
                  <Spacer />
                  <Button
                    size="small"
                    title="Close"
                    onClick={() => setShowTags(false)}
                    sx={{ color: "#9b9b9b" }}
                  >
                    Close
                  </Button>
                  <IconButton
                    title="Manage tags"
                    onClick={() => setShowTags(false)}
                  >
                    <LocalOfferIcon />
                  </IconButton>
                </Stack>
              </Collapse>
            </Box>
          )}
          {/* ************* Event Description ************ */}
          {eventView === "read" && event?.event_abstract ? (
            <Box>
              <Typography variant="h5" sx={{ pt: 7, fontWeight: 700 }}>
                Description
              </Typography>
              <Typography variant="body1" sx={{ pt: 1 }}>
                {event?.event_abstract}
              </Typography>
            </Box>
          ) : (
            <TextField
              id="outlined-textarea"
              label="Description"
              placeholder="Description"
              multiline
              fullWidth
              variant="filled"
              value={description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDescription(event.target.value);
              }}
              sx={{ mt: 4 }}
            />
          )}

          {event?.link_to_pdf && (
            <Box>
              <Typography variant="h5" sx={{ pt: 7, fontWeight: 700 }}>
                Source
              </Typography>
              <Thumbnail src={event.link_to_pdf} />
            </Box>
          )}
        </Box>
        {event?.event_title === "MDT" && (
          <Box
            sx={{
              backgroundColor: "#F7F9FC",
              p: 5,
              borderLeft: "1px solid #E0e0e0",
            }}
          >
            <Comments
              patient={{
                id: 2,
                firstName: "Elijah",
                lastName: "Bailey",
                fullName: "BAILEY, Elijah",
                age: 72,
                dob: "1951-03-05",
                gender: "Male",
                ethnicity: "African",
                nhs: "012 345 6789",
                chief_complaint: "Right hip osteoarthritis, pain",
                conditions: [
                  "Osteoarthritis",
                  "Obesity",
                  "Myocardial infarction",
                  "Stent-LAD Coronary Artery",
                ],
                tlc_upload: true,
                user_reviewed: true,
                team_review: 80,
                mdt_status: "Case Preparation",
                consultant: "Mr David Cash",
                mdtRationale:
                  "To evaluate surgical intervention, optimise medical management, and provide nutritional support.",
                mdtContributors: [
                  { specialty: "MDT Chair", name: "Chair_10" },
                  { specialty: "MDT Coordinator", name: "Coordinator_10" },
                  { specialty: "G.P.", name: "GP_10" },
                  { specialty: "Orthopaedic Surgeon", name: "Surgeon_10" },
                  { specialty: "Cardiologist", name: "Cardiologist_10" },
                  {
                    specialty: "Anesthesiologist",
                    name: "Anesthesiologist_10",
                  },
                  { specialty: "Nutritionist", name: "Nutritionist_10" },
                  { specialty: "Physical Therapist", name: "Physical_10" },
                  {
                    specialty: "Occupational Therapist",
                    name: "Occupational_10",
                  },
                  { specialty: "Nursing", name: "Nursing_10" },
                  { specialty: "Social Worker", name: "SocialWorker_10" },
                ],
                pmh: "Mr. Abara is a 72-year-old, moderately obese (BMI = 36) male of South African descent being considered for a Right Total Hip Replacement secondary to osteoarthritis. Patient’s noteworthy past medical history includes: obesity, 50 pack-years of ongoing tobacco use, and a non-ST-elevation myocardial infarction  ",
                psh: "(NSTEMI) 5 years prior, resulting in a successful stenting of his left anterior descending (LAD) coronary artery. He has no cardiac symptoms, no shortness of breath with ambulation or signs of congestive heart failure (no peripheral oedema, pulmonary rales or orthopnea). ",
                medications: ["Ibuprofen"],
                allergies: ["None"],
                socialHistory:
                  "Patient lives alone and independently but is having considerable difficulty climbing the stairs to his 2nd story flat. Pre-operative MDT evaluation is requested given his cardiac history, obesity and sedentary nature, as well as concern for post-operative support for activities of daily living.",
              }}
            />
          </Box>
        )}
      </Box>
    );
  }

  return (
    // Dialog component to display the form

    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      sx={{
        width: "100%",
        maxWidth: "500px",
        flexShrink: 0,
      }}
    >
      <AppBar position="static">{contentToolbar}</AppBar>
      {content}
    </Drawer>
  );
};

export default EventForm;
