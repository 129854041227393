import merge from "deepmerge";
import { green, grey, orange, deepOrange, teal } from "@mui/material/colors";
import { THEMES } from "../constants";

const bevanBlue = {
  "900": "#122849",
  "800": "#1d3b5b",
  "700": "#234366",
  "600": "#305175",
  "500": "#375779",
};

const customBlue = {
  50: "#E8F2FD",
  100: "#A1CDF7",
  200: "#69AFF2",
  300: "#489DEF",
  400: "#278CEC",
  500: "#0B75DA",
  600: "#0A68C2",
  700: "#005EB8",
  800: "#004C94",
  900: "#003970 ",
};

const fieldfisher = {
  "50": "#F2F7FD",
  "100": "#A7CDF1",
  "200": "#73AFE8",
  "300": "#549DE3",
  "400": "#358CDE",
  "500": "#227ACE",
  "600": "#1D68AF",
  "700": "#185591",
  "800": "#133274",
  "900": "#002D4B",
};

const northwellBlue = {
  "50": "#E4F7FF",
  "100": "#BFEBFF",
  "200": "#59CBFE",
  "300": "#16B6FE",
  "400": "#019ADF",
  "500": "#0a82c8",
  "600": "#007bc2",
  "700": "#00639d",
  "800": "#01639d",
  "900": "#01426F",
};

const phoenixBlue = {
  "500": "#375779",
  "600": "#305175",
  "700": "#234365",
  "800": "#1d3c5c",
  "900": "#122E4C",
};

const ramsayBlue = {
  "50": "#F2F7FD",
  "100": "#A7CDF1",
  "200": "#73AFE8",
  "300": "#549DE3",
  "400": "#358CDE",
  "500": "#227ACE",
  "600": "#1D68AF",
  "700": "#185591",
  "800": "#103C65",
  "900": "#0D3051",
};

const sidelightGrey = {
  "50": "#F2F3F8",
  "100": "#E5E9F1",
  "200": "#CDD3DF",
  "300": "#B1B8C8",
  "400": "#98A1B3",
  "500": "#808A9D",
  "600": "#697286",
  "700": "#525B6F",
  "800": "#3D4557",
  "900": "#272E3F",
};

const thinLayerIndigo = {
  "50": "#ECEEF9",
  "100": "#C9CEEE",
  "200": "#96A1DE",
  "300": "#7785D5",
  "400": "#6374CF",
  "500": "#5061BF",
  "600": "#4A59A6",
  "700": "#3C4A8B",
  "800": "#28356C",
  "900": "#14214E",
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: customBlue[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FFA000",
      contrastText: "#000",
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
    accent: {
      main: orange[600],
    },
    news: {
      zero: "#FFFFFF",
      one: "#FFF0AB",
      two: "#FDC88A",
      three: "#F59680",
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: "#233044",
    header: {
      color: grey[200],
      background: "#233044",
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#1E2A38",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: customBlue[500],
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          stroke: "currentColor",
        },
      },
    },
  },
};

const northwellVariant = merge(defaultVariant, {
  name: THEMES.NORTHWELL,
  palette: {
    primary: {
      main: northwellBlue[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FFA000",
      contrastText: "#000",
    },
    accent: {
      main: orange[600],
    },
  },
  header: {
    indicator: {
      background: northwellBlue[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: northwellBlue[600],
    header: {
      color: "#FFF",
      background: northwellBlue[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: northwellBlue[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});
const fieldfisherVariant = merge(defaultVariant, {
  name: THEMES.FIELDFISHER,
  palette: {
    primary: {
      main: fieldfisher[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: "#5B0A4C",
      contrastText: "#fff",
    },
    accent: {
      main: orange[600],
    },
  },
  header: {
    indicator: {
      background: fieldfisher[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: fieldfisher[700],
    header: {
      color: fieldfisher[800],
      background: "#FFF",
      brand: {
        color: fieldfisher[800],
      },
    },
    footer: {
      color: "#FFF",
      background: fieldfisher[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const ramsayVariant = merge(defaultVariant, {
  name: THEMES.RAMSAY,
  palette: {
    primary: {
      main: ramsayBlue[800],
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FFA000",
      contrastText: "#000",
    },
    accent: {
      main: orange[600],
    },
    background: {
      default: ramsayBlue[50],
      paper: "#FFF",
    },
  },
  header: {
    indicator: {
      background: ramsayBlue[500],
    },
  },
  sidebar: {
    color: "#FFF",
    background: ramsayBlue[700],
    header: {
      color: "#FFF",
      background: ramsayBlue[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: ramsayBlue[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const phoenixVariant = merge(defaultVariant, {
  name: THEMES.PHOENIX,
  palette: {
    primary: {
      main: phoenixBlue[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: "#C6A76F",
      contrastText: "#FFF",
    },
    accent: {
      main: orange[600],
    },
  },
  header: {
    indicator: {
      background: phoenixBlue[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: phoenixBlue[600],
    header: {
      color: "#FFF",
      background: phoenixBlue[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: phoenixBlue[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});
const bevanVariant = merge(defaultVariant, {
  name: THEMES.BEVAN,
  palette: {
    primary: {
      main: bevanBlue[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: "#41b752",
      contrastText: "#FFF",
    },
    accent: {
      main: orange[600],
    },
  },
  header: {
    indicator: {
      background: bevanBlue[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: bevanBlue[700],
    header: {
      color: "#FFF",
      background: bevanBlue[900],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: bevanBlue[900],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});
const sidelightVariant = merge(defaultVariant, {
  name: THEMES.SIDELIGHT,
  palette: {
    primary: {
      main: "#15899b",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FFA000",
      contrastText: "#000",
    },
    accent: {
      main: orange[600],
    },
  },
  header: {
    indicator: {
      background: sidelightGrey[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: sidelightGrey[800],
    header: {
      color: "#FFF",
      background: sidelightGrey[900],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: sidelightGrey[900],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});
const thinLayerVariant = merge(defaultVariant, {
  name: THEMES.THINLAYER,
  palette: {
    primary: {
      main: thinLayerIndigo[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FFA000",
      contrastText: "#000",
    },
    accent: {
      main: orange[600],
    },
    warning: {
      main: deepOrange[900],
    },
  },
  header: {
    indicator: {
      background: thinLayerIndigo[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: thinLayerIndigo[700],
    header: {
      color: "#FFF",
      background: thinLayerIndigo[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: thinLayerIndigo[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: "dark",
    primary: {
      main: customBlue[600],
      contrastText: "#FFF",
    },
    background: {
      default: "#1B2635",
      paper: "#233044",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
    },
    accent: {
      main: orange[600],
    },
  },
  header: {
    color: grey[300],
    background: "#1B2635",
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: "#233044",
  },
});

const blueVariant = merge(defaultVariant, {
  name: THEMES.BLUE,
  palette: {
    mode: "light",
    accent: {
      main: orange[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: customBlue[700],
    header: {
      color: "#FFF",
      background: customBlue[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customBlue[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const greenVariant = merge(defaultVariant, {
  name: THEMES.GREEN,
  palette: {
    primary: {
      main: teal[800],
      contrastText: "#FFF",
    },
    secondary: {
      main: teal[500],
      contrastText: "#FFF",
    },
    accent: {
      main: orange[600],
    },
  },
  header: {
    indicator: {
      background: teal[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: teal[700],
    header: {
      color: "#FFF",
      background: teal[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: teal[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});
const spireVariant = merge(defaultVariant, {
  name: THEMES.SPIRE,
  palette: {
    primary: {
      main: "#026a6c",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FFA000",
      contrastText: "#000",
    },
    accent: {
      main: orange[600],
    },
  },
  header: {
    indicator: {
      background: "#11a0a2",
    },
  },
  sidebar: {
    color: "#FFF",
    background: "#028385",
    header: {
      color: "#FFF",
      background: "#026a6c",
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: "#026a6c",
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const variants: Array<VariantType> = [
  defaultVariant,
  bevanVariant,
  blueVariant,
  darkVariant,
  greenVariant,
  fieldfisherVariant,
  phoenixVariant,
  northwellVariant,
  ramsayVariant,
  sidelightVariant,
  spireVariant,
  thinLayerVariant,
];

export default variants;

export type VariantType = {
  name: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
  };
  header: ColorBgType & {
    search: {
      color: string;
    };
    indicator: {
      background: string;
    };
  };
  footer: ColorBgType;
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string;
      };
    };
    footer: ColorBgType & {
      online: {
        background: string;
      };
    };
    badge: ColorBgType;
  };
};

type MainContrastTextType = {
  main: string;
  contrastText: string;
};
type ColorBgType = {
  color: string;
  background: string;
};
