//react imports
import React from "react";

//MUI imports
import { Box, Card, Typography } from "@mui/material";

//Project imports
import CardTitle from "../card/CardTitle";

const CaseDetails = () => {
  return (
    <Card>
      <CardTitle title="Details" />
      <Box sx={{ px: 5, py: 7 }}>
        {/* Case Id */}
        <Box sx={{ display: "flex", pb: 2 }}>
          <Box sx={{ width: "110px", flexShrink: 0, pr: 2 }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 700, textAlign: "right" }}
            >
              Case Id:
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">12345-EB</Typography>
          </Box>
        </Box>

        {/* Case Name */}
        <Box sx={{ display: "flex", pb: 2 }}>
          <Box sx={{ width: "110px", flexShrink: 0, pr: 2 }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 700, textAlign: "right" }}
            >
              Case Name:
            </Typography>
          </Box>
          <Box>
            {" "}
            <Typography variant="body2">
              NHS Foundation Trust vs EB & Ors
            </Typography>
          </Box>
        </Box>

        {/* Case Type */}
        <Box sx={{ display: "flex", pb: 2 }}>
          <Box sx={{ width: "110px", flexShrink: 0, pr: 2 }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 700, textAlign: "right" }}
            >
              Case Type:
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">Medical Negligence</Typography>
          </Box>
        </Box>

        {/* Case Status*/}
        <Box sx={{ display: "flex", pb: 2 }}>
          <Box sx={{ width: "110px", flexShrink: 0, pr: 2 }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 700, textAlign: "right" }}
            >
              Status:
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">Discovery</Typography>
          </Box>
        </Box>

        {/* Case Summary*/}
        <Box sx={{ display: "flex", pb: 2 }}>
          <Box sx={{ width: "110px", flexShrink: 0, pr: 2 }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 700, textAlign: "right" }}
            >
              Case Summary:
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">
              Elijah Bailey experienced an intraoperative MI during hip surgery,
              with complications likely due to deviations from recommended care
              plan, including missed prehabilitation and incorrect anaesthesia
              choice.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default CaseDetails;
