import React from "react";
import styled from "@emotion/styled";
// import { ReactComponent as RamsayLogo } from "../vendor/Ramsay_Health_Care_logo_horizontal.svg";
// import { ReactComponent as ThinLayerLogo } from "../theme/variants/ThinLayer.svg";

import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItemButtonProps as MuiListItemButtonProps,
  ListItemButton as MuiListItemButton,
} from "@mui/material";

import SidelightLogoPowered from "../vendor/SidelightLogoPowered";

interface ListItemButtonProps extends MuiListItemButtonProps {
  component?: React.ElementType<any> | undefined;
  href?: string;
}

// const StyledLogo = styled(ThinLayerLogo)`
//   height: 40px;
//   width: auto;
//   margin: 10px;
// `;

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)<ListItemButtonProps>`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid container item xs={12} sm={8}>
          <List>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Support" />
            </ListItemButton>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Help Center" />
            </ListItemButton>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Privacy" />
            </ListItemButton>
            <ListItemButton component="a" href="#">
              <ListItemText primary="Terms of Service" />
            </ListItemButton>
          </List>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={4}
          alignItems="center"
          justifyContent="flex-end"
        >
          {/* <StyledLogo /> */}
          <SidelightLogoPowered />
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
