import React from "react";
import { useDispatch } from "react-redux";
import { changeEvent } from "../../redux/slices/event";
import { Timeline } from "../../types/extraction";
import {
  DataGridPro,
  gridClasses,
  GridEventListener,
  GridToolbar,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";

// Importing necessary components from MUI
import { Box, Link, Stack } from "@mui/material";

import Tag from "./Tag";

interface TableTimelineProps {
  timeline: Timeline;
  handleOpen: () => void;
}

// Component to render a table timeline
const TableTimeline = ({ timeline, handleOpen }: TableTimelineProps) => {
  const dispatch = useDispatch();
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    dispatch(changeEvent(params.id));
    handleOpen();
  };
  // Define the content of the component
  return (
    <Box sx={{ width: "100%", height: "95%" }}>
      <DataGridPro
        onRowClick={handleRowClick}
        getRowHeight={() => "auto"}
        disableDensitySelector // Disable the density selector
        disableColumnSelector // Disable the column selector
        checkboxSelection
        slots={{ toolbar: GridToolbar }} // Set the toolbar slot to GridToolba
        slotProps={{
          toolbar: {
            showQuickFilter: true, // Show the quick filter in the toolbar
          },
        }}
        sx={{
          pt: 1,
          [`& .${gridClasses.cell}`]: {
            py: 3,
          },
        }}
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          }, // Set the pinned columns
          // sorting: { sortModel: [{ field: "event_date", sort: "asc" }] },
        }}
        rows={timeline} // Set the rows of the DataGridPro to the timeline data
        columns={[
          // Define the columns for the DataGridPro
          {
            field: "event_date",
            headerName: "Start date",
            width: 120,
            type: "date",
            valueFormatter: (params) => {
              return params ? new Date(params).toLocaleDateString() : "N/A";
            },
          },
          {
            field: "event_end",
            headerName: "End date",
            width: 120,
            type: "date",
            valueFormatter: (params) => {
              return params ? new Date(params).toLocaleDateString() : "N/A";
            },
          },
          { field: "event_type", headerName: "Type", width: 120 },
          {
            field: "event_abstract",
            headerName: "Description",
            flex: 2,
            minWidth: 200,
          },
          {
            field: "tags",
            headerName: "Tags",
            renderCell: (params) => {
              const { row } = params;
              return (
                <Stack spacing={1} sx={{ flexWrap: "wrap" }}>
                  {row.tags &&
                    row.tags.map((tag: any, index: number) => (
                      <Tag
                        key={index}
                        id={tag.id}
                        name={tag.name}
                        color={tag.color}
                        bgcolor={tag.bgcolor}
                        fullWidth={true}
                      />
                    ))}
                </Stack>
              );
            },
            valueGetter: (row: any[] | undefined) => {
              if (!row) {
                return "";
              }
              return row.map((tag) => tag.name).join(", ");
            },
            width: 120,
          },
          {
            field: "event_title",
            headerName: "Source",
            renderCell: (params) => {
              return (
                <Link
                  sx={{ fontWeight: 800 }}
                  href={params.row.link_to_pdf}
                  target="_blank"
                >
                  {params.value}
                </Link>
              );
            },
            flex: 1,
            minWidth: 150,
          },
          {
            field: "author",
            headerName: "Author",

            width: 150,
          },
          { field: "author_role", headerName: "Role", width: 130 },
        ]}
        disableRowSelectionOnClick // Disable row selection on click
      />
    </Box>
  );
};

export default TableTimeline;
