const exampleTags = [
  { id: 44, name: "Accessibility", bgcolor: "#efdfed", color: "#491644" },
  { id: 11, name: "Anaesthesia", bgcolor: "#f9e1ec", color: "#6b1c40" },
  { id: 48, name: "Breach", bgcolor: "#f4cdc6", color: "#2a0b06" },
  { id: 41, name: "Care Plan", bgcolor: "#CCE2D9", color: "#005a30" },
  { id: 14, name: "Causation", bgcolor: "#cce2d8", color: "#005a30" },
  { id: 26, name: "CHF", bgcolor: "#f4cdc6", color: "#2a0b06" },
  { id: 19, name: "Completion", bgcolor: "#CCE2D9", color: "#005a30" },
  { id: 13, name: "Complication", bgcolor: "#fff7bf", color: "#594d00" },
  { id: 31, name: "Continuity", bgcolor: "#e8f1f8", color: "#0c2d4a" },
  { id: 28, name: "ECHO", bgcolor: "#bbd4ea", color: "#0c2d4a" },
  { id: 27, name: "Injury", bgcolor: "#f4cdc6", color: "#2a0b06" },
  { id: 16, name: "Ischemia", bgcolor: "#f4cdc6", color: "#2a0b06" },
  { id: 10, name: "IT Outage", bgcolor: "#e8f1f8", color: "#0c2d4a" },
  { id: 18, name: "Judgment", bgcolor: "#bbd4ea", color: "#0c2d4a" },
  { id: 15, name: "Labs", bgcolor: "#efdfed", color: "#491644" },
  { id: 24, name: "MI", bgcolor: "#CCE2D9", color: "#005a30" },
  { id: 23, name: "Monitoring", bgcolor: "#e5e6e7", color: "#282d30" },
  { id: 46, name: "No Change", bgcolor: "#e8f1f8", color: "#0c2d4a" },
  { id: 25, name: "Plan Missed", bgcolor: "#fcd6c3", color: "#6e3619" },
  { id: 20, name: "Outcome", bgcolor: "#e8f1f8", color: "#0c2d4a" },
  { id: 29, name: "Post-Procedure", bgcolor: "#d4ecea", color: "#10403c" },
  { id: 43, name: "Prehab", bgcolor: "#e8f1f8", color: "#0c2d4a" },
  { id: 47, name: "Prep", bgcolor: "#fff7bf", color: "#594d00" },
  { id: 42, name: "Prevention", bgcolor: "#f4cdc6", color: "#2a0b06" },
  { id: 17, name: "Procedure", bgcolor: "#d4ecea", color: "#10403c" },
  { id: 32, name: "Rehabilitation", bgcolor: "#cce2d8", color: "#005a30" },
  { id: 49, name: "Risk", bgcolor: "#f9e1ec", color: "#6b1c40" },
  { id: 45, name: "Routine", bgcolor: "#fcd6c3", color: "#6e3619" },
  { id: 12, name: "Resuscitation", bgcolor: "#e5e6e7", color: "#282d30" },
  { id: 30, name: "Stability", bgcolor: "#cce2d8", color: "#005a30" },
  { id: 22, name: "Transfer", bgcolor: "#d4ecea", color: "#10403c" },
];

export default exampleTags;
