import { ReactComponent as ThinLayer } from "../vendor/ThinLayer-White.svg";
import { ReactComponent as UCLH } from "../vendor/uclh-logo-sidebar.svg";
import { ReactComponent as NHS } from "../vendor/NHS-logo-sidebar.svg";
import { ReactComponent as Ramsay } from "../vendor/ramsay-white.svg";
import { ReactComponent as Northwell } from "../vendor/Northwell_Sidebar.svg";
import { ReactComponent as Phoenix } from "../vendor/phoenix-home-logo-sidebar.svg";
import { ReactComponent as Bevan } from "../vendor/bevanbrittan-logo.svg";
// import { ReactComponent as FieldFisher } from "../vendor/fieldfisher.svg";
// import { ReactComponent as Sidelight } from "../vendor/Sidelight.svg";
import SidelightLogo from "../vendor/SidelightLogo";

import Spire from "../vendor/spirehealthsidebar.png";
import FieldFisher from "../vendor/fieldfisher.png";

import styled from "@emotion/styled";

const ThinLayerLogo = styled(ThinLayer)`
  height: 40px;
`;
const UCLHLogo = styled(UCLH)`
  height: 40px;
`;

const NHSLogo = styled(NHS)`
  height: 40px;
  margin-top: 5px;
`;

const PhoenixLogo = styled(Phoenix)`
  height: 40px;
`;

const BevanLogo = styled(Bevan)`
  height: 40px;
`;

// const FieldFisherLogo = styled(FieldFisher)`
//   height: 40px;
// `;

const RamsayLogo = styled(Ramsay)`
  height: 40px;
`;

const NorthwellLogo = styled(Northwell)`
  height: 40px;
`;
// const SpireLogo = styled(Spire)`
//   height: 40px;
// `;

function SidebarLogo() {
  const storedTheme = localStorage.getItem("theme");

  switch (storedTheme) {
    case '"GREEN"':
      return <UCLHLogo />;
    case '"BLUE"':
      return <NHSLogo />;
    case '"PHOENIX"':
      return <PhoenixLogo />;
    case '"THINLAYER"':
      return <ThinLayerLogo />;
    case '"RAMSAY"':
      return <RamsayLogo />;
    case '"BEVAN"':
      return <BevanLogo />;
    case '"FIELDFISHER"':
      return <img src={FieldFisher} height="29px" alt="fieldfisher logo" />;
    case '"NORTHWELL"':
      return <NorthwellLogo />;
    case '"SPIRE"':
      return <img src={Spire} height="29px" alt="spire logo" />;
    case '"SIDELIGHT"':
      return <SidelightLogo />;
    default:
      return <ThinLayerLogo />;
  }
}

export default SidebarLogo;
