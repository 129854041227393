// react imports
import React from "react";
import { Helmet } from "react-helmet-async";

// MUI imports
import {
  Box,
  Breadcrumbs,
  Card,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import styled from "@emotion/styled";

// Project imports
import TLPagebar from "../../components/navbar/appBar/TLPageBar";
import CaseDetails from "../../components/caseRender/CaseDetails";
// import CaseHistory from "../../components/caseRender/CaseHistory";
import KeyDates from "../../components/caseRender/KeyDates";
import Parties from "../../components/caseRender/Parties";
import CaseDocuments from "../../components/caseRender/Documents";
import ChronologyFullPage from "../../components/chronology/ChronologyFullPage";
// import CaseCommentFeed from "../../components/caseRender/CaseCommentFeed";
// import Actions from "../../components/caseRender/Actions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Styled component for page content
const PageContent = styled(Box)`
  padding: 6px 24px 24px 24px;
`;

function CaseDetail() {
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>NHS Foundation Trust vs EB & Ors</title>
      </Helmet>
      <TLPagebar
        title="NHS Foundation Trust vs EB & Ors"
        leftTools={
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ ml: 5 }}
          >
            <Tab label="DETAILS" {...a11yProps(0)} />
            <Tab label="CHRONOLOGY" {...a11yProps(1)} />
            <Tab label="DOCUMENTS" {...a11yProps(2)} />
          </Tabs>
        }
        breakpointLeft="lg"
      />
      <PageContent>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 4, px: 1 }}>
          <Link color="inherit" href="/">
            Home
          </Link>
          <Link color="inherit" href="/dashboard">
            Dashboard
          </Link>
          <Typography color="textPrimary">
            NHS Foundation Trust vs EB & Ors
          </Typography>
        </Breadcrumbs>
        <CustomTabPanel value={tabValue} index={0}>
          <Grid container spacing={6}>
            <Grid
              classes="row-1-col-2"
              item
              xs={12}
              md={12}
              lg={3}
              xl={3}
              xxl={3}
            >
              <Stack spacing={6}>
                <CaseDetails />
                <Parties />
              </Stack>
            </Grid>
            <Grid
              classes="row-1-col-1"
              item
              xs={12}
              md={12}
              lg={9}
              xl={9}
              xxl={9}
            >
              <Stack spacing={6}>
                <KeyDates />
              </Stack>
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <Card sx={{ py: 4, px: 7, height: "77vh" }}>
            <ChronologyFullPage title="Elijah Bailey Chronology" />
          </Card>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <CaseDocuments />
        </CustomTabPanel>
      </PageContent>
    </React.Fragment>
  );
}

export default CaseDetail;
