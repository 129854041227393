const caseListData = [
  {
    id: 1,
    case_id: "13052023-MJ",
    client_name: "Mary Johnson",
    case_name: "NHS Foundation Trust vs MJ & Ors",
    lead_solicitor: "James Oliver",
    case_type: "Medical Negligence",
    case_stage: "Pre-action stage",
    next_deadline: "2024-11-18",
    next_hearing: "2024-12-15",
    key_documents: "Expert Witness Report, Lab Results",
    page_count: 6435,
  },
  {
    id: 2,
    case_id: "04082022-JS",
    client_name: "John Smith",
    case_name: "Private Health Services vs JS",
    lead_solicitor: "Sarah Hughes",
    case_type: "Personal Injury",
    case_stage: "Evidence Gathering",
    next_deadline: "2024-11-20",
    next_hearing: "2024-11-29",
    key_documents: "Incident Report, Witness Statements",
    page_count: 2315,
  },
  {
    id: 3,
    case_id: "23092019-EB",
    client_name: "Elijah Bailey",
    case_name: "NHS Foundation Trust vs EB & Ors",
    lead_solicitor: "David Clark",
    case_type: "Medical Negligence",
    case_stage: "Letter of Claim",
    next_deadline: "2024-11-23",
    next_hearing: "2024-11-28",
    key_documents: "Medical Records, Treatment Plan",
    page_count: 3415,
  },
  {
    id: 4,
    case_id: "30032021-JD",
    client_name: "Jane Doe",
    case_name: "Doe vs. NHS",
    lead_solicitor: "Emily Carter",
    case_type: "NHS Foundation Trust vs JD",
    case_stage: "Case Management Conference",
    next_deadline: "2024-11-25",
    next_hearing: "2024-12-30",
    key_documents: "Medical Reports, Imaging Scans",
    page_count: 5733,
  },
  {
    id: 5,
    case_id: "07082021-MB",
    client_name: "Michael Brown",
    case_name: "Private Clinic vs MB",
    lead_solicitor: "Michael Stone",
    case_type: "Medical Malpractice",
    case_stage: "Letter of Response",
    next_deadline: "2024-11-27",
    next_hearing: null,
    key_documents: "Surgical Records, Anesthesia Logs",
    page_count: 12942,
  },
  {
    id: 6,
    case_id: "01102021-LG",
    client_name: "Linda Green",
    case_name: "NHS Foundation Trust vs LG & Ors",
    lead_solicitor: "Anna Thompson",
    case_type: "Product Liability",
    case_stage: "Discovery",
    next_deadline: "2024-11-22",
    next_hearing: null,
    key_documents: "Product Analysis, Usage Records",
    page_count: 10050,
  },
];

export default caseListData;
