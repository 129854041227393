import React from "react";
import { SidebarItemsType } from "../../../types/sidebar";

import {
  AdminPanelSettings,
  CoPresent,
  FactCheck,
  Groups,
  // Person,
  ViewKanban,
  // ViewTimeline,
} from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TLIcon from "../../icons/TLIcon";
import {} from "@mui/icons-material";

const PatientIcon = () => <TLIcon name="personal_injury" />;
// const RamsayIcon = () => <TLIcon name="RamsayIcon" />;
const OncologyIcon = () => <TLIcon name="OncologyIcon" />;
const OrthoIcon = () => <TLIcon name="OrthoIcon" />;

// const homeSection = [
//   {
//     href: "/",
//     icon: RamsayIcon,
//     title: "Dashboard",
//     amount: 0,
//     category: "Home",
//   },
// ] as SidebarItemsType[];

const listsSection = [
  {
    href: "/patients",
    icon: PatientIcon,
    title: "PATIENTS",
    amount: 0,
    children: [],
  },
] as SidebarItemsType[];
const now = new Date();
const week = 7;

const plusOne = new Date();
plusOne.setDate(plusOne.getDate() + week * 1);
const plusTwo = new Date();
plusTwo.setDate(plusTwo.getDate() + week * 2);
const plusThree = new Date();
plusThree.setDate(plusThree.getDate() + week * 3);
const plusFour = new Date();
plusFour.setDate(plusFour.getDate() + week * 4);

const assesmentSection = [
  {
    href: "/assesment",
    icon: FactCheck,
    title: "PRE-ASSESMENT",
    amount: 0,
    children: [
      {
        href: "/management",
        icon: ViewKanban,
        title: "Management",
        amount: 0,
        category: "CoPresent",
      },
    ],
  },
] as SidebarItemsType[];

const mdtSection = [
  {
    href: "/mdts",
    icon: Groups,
    title: "MDT MEETINGS",
    children: [
      {
        href: "/live/1",
        icon: CoPresent,
        title: "Live Meeting",
        amount: 0,
        category: "CoPresent",
      },
      {
        href: "/mdt/1",
        icon: OrthoIcon,
        title: now.toLocaleDateString() + ", 2.30PM",
        amount: 12,
        category: "Orthopaedics",
      },
      {
        href: "/mdt/2",
        icon: OncologyIcon,
        title: plusOne.toLocaleDateString() + ", 2.30PM",
        amount: 10,
        category: "Oncology",
      },
      {
        href: "/mdt/3",
        icon: OrthoIcon,
        title: plusTwo.toLocaleDateString() + ", 2.30PM",
        amount: 11,
        category: "Orthopaedics",
      },
      {
        href: "/mdt/4",
        icon: OncologyIcon,
        title: plusThree.toLocaleDateString() + ", 2.30PM",
        amount: 11,
        category: "Oncology",
      },
      {
        href: "/mdt/5",
        icon: OrthoIcon,
        title: plusFour.toLocaleDateString() + ", 2.30PM",
        amount: 8,
        category: "Orthopaedics",
      },
    ],
  },
] as SidebarItemsType[];

const screensSection = [
  {
    href: "/legal",
    icon: AdminPanelSettings,
    title: "LEGAL",
    children: [
      {
        href: "/dashboard",
        icon: DashboardIcon,
        title: "Dashboard",
        amount: 0,
        category: "Chronology",
      },
      // {
      //   href: "/chronology",
      //   icon: ViewTimeline,
      //   title: "Chronology",
      //   amount: 0,
      //   category: "Chronology",
      // },
      // {
      //   href: "/management",
      //   icon: ViewKanban,
      //   title: "Management",
      //   amount: 0,
      //   category: "CoPresent",
      // },
    ],
  },
] as SidebarItemsType[];

const navItems = [
  // {
  //   title: "",
  //   pages: homeSection,
  //   custom: false,
  // },
  {
    title: "Lists",
    pages: listsSection,
    custom: false,
  },

  {
    title: "MDT",
    pages: mdtSection,
    custom: true,
  },
  {
    title: "Assesment",
    pages: assesmentSection,
    custom: false,
  },
  {
    title: "Demo",
    pages: screensSection,
    custom: false,
  },
];

export default navItems;
